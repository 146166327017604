<template>
  <div>
    <b-form-group
      label="Birim Fiyat"
      :label-for="'price_' +itemKey"
    >
      <template v-if="isRequired">
        <validation-provider
          #default="{ errors }"
          name="Birim Fiyat"
          rules="required"
        >
          <b-input-group append="TL">
            <cleave
              :id="'price_' + itemKey"
              v-model="dataItem[itemKey].price"
              class="form-control"
              :raw="true"
              :options="options"
              placeholder="Giriniz"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </template>
      <template v-else>
        <b-input-group append="TL">
          <cleave
            :id="'price_' + itemKey"
            v-model="dataItem[itemKey].price"
            class="form-control"
            :raw="true"
            :options="options"
            placeholder="Giriniz"
          />
        </b-input-group>
      </template>
    </b-form-group>
  </div>
</template>
<script>
import {
  BFormGroup, BInputGroup,
} from 'bootstrap-vue'
import { localize, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import Cleave from 'vue-cleave-component'

export default {
  name: 'PriceCard',
  components: {
    BInputGroup,
    ValidationProvider,
    BFormGroup,
    Cleave,
  },
  props: {
    itemKey: {
      type: Number,
      required: true,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      required,
      options: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stockLines/dataItem']
    },
  },
  created() {
    localize('tr')
  },

}
</script>
